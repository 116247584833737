@charset "utf-8";

/*
 * Global
 */
#content {
	padding: 0;
}
.ie8 #container.login, .ie8 #container.error-bg {
	/* Prevents background reloading on IE8 with RWD plugin */
	background-image: url("/images/personalizer/login_bg.jpg"); 
}
@media (min-width: 768px) {
	#container.login, #container.error-bg {
		background-image: url("/images/personalizer/login_bg.jpg");
	}
	.error-pg #content {
		min-height: 400px;
	}
}
.button.cancel:not(.deactive) {
	color: var(--neutral-white);
}
.button + .button, .button + .plain-text-btn, .button + .tertiary-btn {
	margin-left: 1em;
}
/* Overrides the red-btn, green-btn, blue-btn styles in normalize and defaults to secondary-btn */
.secondary-btn.red-btn, .secondary-btn.red-btn.selected, .secondary-btn.red-btn:hover, .secondary-btn.red-btn:focus,
.secondary-btn.green-btn, .secondary-btn.green-btn.selected, .secondary-btn.green-btn:hover, .secondary-btn.green-btn:focus,
.secondary-btn.blue-btn, .secondary-btn.blue-btn.selected, .secondary-btn.blue-btn:hover, .secondary-btn.blue-btn:focus {
    background: 0 0;
    color: var(--primary-blue);
}
.loading {
	display: inline-block;
	font-size: 1.153846153846154em; /* 15px */
	font-weight: 700;
}
.loading img {
	height: 26px;
	width: 26px;
	vertical-align: middle;
}
@media screen and (max-width: 870px) {
	.table-responsive {
		background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, var(--neutral-white)), color-stop(100%, rgba(255, 255, 255, 0))), -webkit-gradient(linear, 100% 50%, 0% 50%, color-stop(0%, var(--neutral-white)), color-stop(100%, rgba(255, 255, 255, 0))), -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, var(--neutral-medium)), color-stop(100%, rgba(195, 195, 197, 0))), -webkit-gradient(linear, 100% 50%, 0% 50%, color-stop(0%, var(--neutral-medium)), color-stop(100%, rgba(195, 195, 197, 0)));
		background-image: -webkit-linear-gradient(left, var(--neutral-white), rgba(255, 255, 255, 0)), -webkit-linear-gradient(right, var(--neutral-white), rgba(255, 255, 255, 0)), -webkit-linear-gradient(left, var(--neutral-medium), rgba(195, 195, 197, 0)), -webkit-linear-gradient(right, var(--neutral-medium), rgba(195, 195, 197, 0));
		background-image: -moz-linear-gradient(left, var(--neutral-white), rgba(255, 255, 255, 0)), -moz-linear-gradient(right, var(--neutral-white), rgba(255, 255, 255, 0)), -moz-linear-gradient(left, var(--neutral-medium), rgba(195, 195, 197, 0)), -moz-linear-gradient(right, var(--neutral-medium), rgba(195, 195, 197, 0));
		background-image: -o-linear-gradient(left, var(--neutral-white), rgba(255, 255, 255, 0)), -o-linear-gradient(right, var(--neutral-white), rgba(255, 255, 255, 0)), -o-linear-gradient(left, var(--neutral-medium), rgba(195, 195, 197, 0)), -o-linear-gradient(right, var(--neutral-medium), rgba(195, 195, 197, 0));
		background-image: linear-gradient(left, var(--neutral-white), rgba(255, 255, 255, 0)), linear-gradient(right, var(--neutral-white), rgba(255, 255, 255, 0)), linear-gradient(left, var(--neutral-medium), rgba(195, 195, 197, 0)), linear-gradient(right, var(--neutral-medium), rgba(195, 195, 197, 0));
		background-position: 0 0, 100% 0, 0 0, 100% 0;
		background-repeat: no-repeat;
		background-color: var(--neutral-white);
		background-size: 4em 100%, 4em 100%, 2em 100%, 2em 100%;
		background-attachment: local, local, scroll, scroll; 
	}
	.table-responsive table {
		background: none;
	}
	.rgba .table-responsive caption {
		background: rgba(200,200,200,.5);
	}
	.rgba .table-responsive th {
		background: rgba(220,220,220,.5);
	}
}
.cp-img-border {
	border: 1px solid var(--neutral-cool-medium);
}
@media (max-width: 767px) {
	#feedbackify {
		display: none;
	}
}
@media (max-width: 599px) {
	.horizontal-field > label {
		display: block;
		text-align: left;
		vertical-align: middle;
	}
	.horizontal-field > label + input, .horizontal-field > label + select, .horizontal-field > label + textarea {
		margin-left: 0;
	}
}
.add-print-btn {
	position: relative;
}
.print-btn {
	padding: .5em 1em;
	position: absolute;
	right: 5px;
	top: 3px;
}
#popupContainer .print-btn {
	top: 5px;
}
.print-btn > .ir {
	margin-top: -3px;
	vertical-align: middle;
}
.print-btn .printing {
	background: url(/images/global/ajax_loader.gif) no-repeat center center;
	background-size: cover;
}
.green-bg {
	background-color: var(--auxiliary-green);
}
.cp-dark-blue {
	background-color: var(--auxiliary-blue-dark);
}
.cp-light-blue {
	background-color: var(--neutral-cool-medium);
}
.cp-princess-blue {
	background-color: var(--primary-blue);
}
.cp-dark-blue a, .cp-light-blue a, .cp-princess-blue a {
	color: var(--neutral-white);
}

/* ADA : h2 focus */
h2[tabindex="-1"]:focus {
    outline: none;
}
	
/* Tooltip */
.web-font .ui-tooltip-title, .web-font .qtip-title {
	font-family: var(--roboto-condensed);
	font-weight:400;
	letter-spacing: 1px;
}
.qtip .qtip-content {
	overflow: visible;
}

/* Accordion */
.gray-f1-bg > .accordion, .gray-f1-bg > .rwd-accordion {
	border-top: none;
	margin-top: 0;
}
.accordion-wrapper:first-child, .rwd-accordion-wrapper:first-child, .accordion-wrapper:only-child, .rwd-accordion-wrapper:only-child {
	margin-top: 0;
}
.accordion-wrapper:only-child, .rwd-accordion-wrapper:only-child {
	margin-bottom: 0;
}
.rwd-accordion-header-select .drawer-control {
	margin-left: auto;
	margin-right: auto;
}
.accordion-control-text {
	line-height: 1em;
}

/*
 * Accordion style customizations
 */
button.accordion-btn-icon:after {
	content: "";
	display: block;
	background: url(/images/global/personalizer/sprite_cp_icons.svg) no-repeat;
	background-position: -633px -130px;
    height: 18px;
    width: 64px;
    margin-left: auto;
    margin-right: auto;
}
button.selected.accordion-btn-icon:after {
	background-position: -710px -130px;
}
button.accordion-btn-icon, button.accordion-btn-icon:hover, button.accordion-btn-icon:focus {
	text-decoration: none;
}

/**
 * Global Styles
 */
@media screen and (max-width: 767px) {
	#cp-header {
		padding-top: 0;
	}
}

/*
 * CP Summary
 */
.summary-pg #content {
	margin-left: auto;
	margin-right: auto;
	max-width: none;
	padding-top: 0;
}
.booking-ship-summary:before {
	background: rgba(255,255,255,.9);
	content: '';
	display: block;
	height: 1em;
	width: 100%;
}
.summary-section {
	padding: 2.5em 0 3em;
}
.summary-section.confirmation-details, .summary-section.ship-stateroom-details, .summary-section.travel-agent {
	background: rgba(76,108,141,.75);/*.3*/
}
.no-rgba .summary-section.confirmation-details, .no-rgba .summary-section.ship-stateroom-details, .no-rgba .summary-section.travel-agent {
	background: var(--neutral-cool-dark);
}
.summary-section.share-the-savings, .summary-section.future-cruise-credit {
	background: rgba(123,147,148,.95);
}
.no-rgba .summary-section.share-the-savings, .no-rgba .summary-section.future-cruise-credit {
	background: var(--neutral-cool-medium);
}
.summary-section.ship-stateroom-details, .summary-section.travel-agent, .share-the-savings + .future-cruise-credit {
	padding-top: 0;
}
.summary-section.recommendations {
	background: rgba(255,255,255,.95);
}
.no-rgba .summary-section.recommendations {
	background: var(--neutral-white);
}
.booking-summary-list {
	margin: 0;
}
.booking-summary-list > li + li {
	border-top: 1px solid var(--neutral-light);
}
@media (max-width: 640px) {
	.booking-summary-list > li:first-child {
		border-top: 1px solid var(--neutral-light);
	}
}
.recommendations a {
	display: block;
}
@media screen and (max-width: 639px) {
	.recommendations a {
		margin-top: 2.5em;
	}
	.recommendations a:first-child {
		margin-top: 0;
	}
}
.recommendations h3 {
	margin-bottom: 0;
}
.recommendations p {
	margin: .25em 0 1.5em;
}
.recommendations img {
	margin: 0 auto;
}

/*
 * CP Summary CTA Buttons
 */
.summary-cta {
	font-size: 1em;
	/*margin: 145px auto 0;*/
	/*margin: 245px auto 0;*/
	max-width: 768px;
	text-align: center;
	margin: 0 auto;
}
@media (max-width: 479px) {
	.summary-cta {
		/*margin-top: 34%;*/
		margin-top: 3%;
	}
}
@media (min-width: 480px) and (max-width: 767px) {
	.summary-cta {
		/*margin-top: 38%;
		margin-top: 10%;*/

	}
}
@media (max-width: 767px) {
	.summary-cta.no-top-margin {
		margin-top: 0;
	}
}
.share-the-savings-btn, .volunteer-btn {
	background-clip: padding-box;
	color: var(--neutral-white);
	display: inline-block;
	line-height: 1.2;
	padding: 10px;
	text-decoration: none;
	text-align: center;
	width: 100%;
}
.share-the-savings-btn, .volunteer-btn {
	background-color: rgba(37,23,69,.8);
}
.share-the-savings-btn + .volunteer-btn {
	border-left: 1px solid transparent;
}
.share-the-savings-btn:hover, .volunteer-btn:hover {
	background-color: rgba(37,23,69,1);
}
.no-rgba .share-the-savings-btn, .no-rgba .volunteer-btn {
	background-color: var(--auxiliary-red-dark);
}
.no-rgba .share-the-savings-btn:hover, .no-rgba .volunteer-btn:hover {
	background-color: var(--auxiliary-blue-dark);
}
.top-line, .bottom-line {
	white-space: nowrap;
}
.bottom-line {
	font-size: 1.25em;
}

/* 2 cta links */
.cta-btn-2 > a > .top-line, .cta-btn-2 > a > .bottom-line {
	display: block;
}
.cta-btn-2 > .share-the-savings-btn, .cta-btn-2 > .volunteer-btn {
	font-size: 1em;
	text-align: left;
	width: 50%;
}
.cta-btn-2 > .share-the-savings-btn {
	text-align: right;
}
.cta-btn-2 > .volunteer-btn {
	text-align: left;
}

/* 1 cta links */
.cta-btn-1 > a > .top-line, .cta-btn-1 > a > .bottom-line {
	display: inline-block;
}
.cta-btn-1 > .share-the-savings-btn, .cta-btn-1 > .volunteer-btn {
	font-size: 0.925em;
}

/* 0 cta links */
@media (min-width: 360px) {
	.bottom-line {
		font-size: 1.416666666666666em;
	}
	.share-the-savings-btn, .volunteer-btn {
		padding:10px 15px;
	}
	.cta-btn-1 > .share-the-savings-btn, .cta-btn-1 > .volunteer-btn {
		font-size: 1em;
	}
	.cta-btn-2 > .share-the-savings-btn, .cta-btn-2 > .volunteer-btn {
		font-size: 0.925em;
	}
	
}



@media (min-width: 480px) {
	.cta-btn-1 .top-line, .cta-btn-1 .bottom-line {
		display: block;
	}
	.cta-btn-1 > .share-the-savings-btn, .cta-btn-1 > .volunteer-btn {
		font-size: 1.078em;
		text-align: left;
		/*width: 50%;*/
	}
}
@media (min-width: 568px) {
	
	.bottom-line {
		font-size: 1.384615384615385em;
	}
	/* 2 cta links */
	.cta-btn-2 .share-the-savings-btn, .cta-btn-2 .volunteer-btn {
		font-size: 1em;
		text-align: left;
		width: 33.3333333333333%;
	}
	
	/* 1 cta link */
	.cta-btn-1 .share-the-savings-btn, .cta-btn-1 .volunteer-btn {
		font-size: 1.23em;
	}
	
}
@media (min-width: 768px) {
	.bottom-line {
		font-size: 1.55em;
	}
	
	/* 2 cta links */
	.cta-btn-2 .share-the-savings-btn, .cta-btn-2 .volunteer-btn {
		font-size: 1.23em;
	}
	
	/* 1 cta link */
	.cta-btn-1 .share-the-savings-btn, .cta-btn-1 .volunteer-btn {
		font-size: 1.384615384615385em;
		width: 40%;
	}
	
	.cta-btn-0 .bottom-line {
		font-size: 1.4285714em;
	}
}
@media print {
	.summary-cta {
		margin-top: 0;
	}
	.share-the-savings-btn, .volunteer-btn {
		color: var(--neutral-black);
	}
}


/* Lightbox print */
#lightbox-print {
	position: absolute;
	right: 30px;
	top: 70px;
}
.lt-ie8 #lightbox-print .pc-icons {
	display: block;
	left: -28px;
	position: absolute;
	top: 0;
}
#lightbox-print + .scrolling-pc {
	border: 1px solid var(--neutral-light);
	height: 300px;
	margin: 50px 15px 30px;
	padding: 15px;
}


/*
 * Login / Logout
 */
.login-pg #content {
	padding-top: 0;
}
.login-pg #auPrivacyNotice {
	clear: both;
}
.cp-nameplate {
	padding: 15px; 
	max-width: 525px;
	margin: 0 auto;
}
.cp-title {
	color: var(--neutral-white);
	display: block;
	font-size: 2.4615384em; /*32px*/
	line-height: 1;
	padding-bottom: .15em;
	text-shadow:  0 2px 2px rgba(0,0,0,.75);
}
.cp-headline {
	font-size: 1.3846153em; /*18px*/
	line-height: 1.125;
}
.cp-teaser {
	font-size: 1em; /*13px*/
}
.cp-nameplate .line-break {
	white-space: nowrap;
}
.signin-login-wrapper, .login-wrapper {
	width: 100%;
	max-width: 525px;
	margin: 0 auto 30px;
}
.cp-signin-login, .cp-signin-login-divider {
	margin: 0 auto;
	padding: 15px;
	position: relative;
	width: 100%;
}
@media (min-width: 360px) {
	.cp-title {
		font-size: 2.8461538em; /*37*/
	}
	.cp-headline {
		font-size: 1.5384615em; /*20*/
	}
}
@media (min-width: 525px) {
	.cp-title {
		font-size: 3.8461538em; /*50*/
	}
	.cp-headline {
		font-size: 1.8461538em; /*24px*/
	}
	.cp-teaser {
		font-size: 1.076923em; /*14px*/
	}
	.cp-nameplate {
		margin-top: 30px;
	}
}
@media (min-width: 768px) {
	.cp-nameplate {
		max-width: 768px;
		margin: 30px auto;
	}
	.cp-nameplate + .cp-nameplate{
		margin-top: 3em;
	}
	.cp-title {
		font-size: 4.2307692em; /*55px*/
	}
	.cp-headline {
		font-size: 2.4615384em; /*32px*/
	}
	.cp-teaser {
		font-size: 1.3846153em; /*18px*/
	}
	.signin-login-wrapper {
		max-width: 100%;
	}
	.login-wrapper {
		max-width: 440px;
	}
	.signin-login-wrapper > .cp-signin-login, .signin-login-wrapper > .cp-signin-login-divider {
		float: left;
	}
	.cp-signin-login .fieldset-block, .cp-signin-login .booking-list {
		min-height: 320px;
	}
	.signin-login-wrapper > .cp-signin-login-divider {
		width: 8.333333333333332%;
		padding-top: 160px;
	}
	.signin-login-wrapper > .cp-signin-login {
		width: 45.83333335%;
		margin: 0;
	}
}
.cp-signin-login .fieldset-block {
	margin: 0;
}
.cp-signin-login .horizontal-field > input[type="text"], .cp-signin-login .horizontal-field > input[type="email"], .cp-signin-login .horizontal-field > input[type="password"], .cp-signin-login .horizontal-field > select, .login_month > select, .login_date > select, .login_year > select {
	width: 100%;
}
#signin-msg, #login-msg {
	display: block;	
}
#login-msg .errorMessage {
	margin-bottom: 0;
}
#signin-title, #login-title {
	margin-top: 0;
}
.login_month, .login_date, .login_year {
	width: 100%;
}
@media (max-width: 919px) {
	.cp-signin-login .horizontal-field > input[type="text"], .cp-signin-login .horizontal-field > input[type="email"], .cp-signin-login .horizontal-field > input[type="password"] {
		margin-left: 0;
	}
	.cp-signin-login .horizontal-fieldset > legend + div {
		margin-top: 1em;
	}
	.cp-signin-login .horizontal-fieldset > input[type="text"], .cp-signin-login .horizontal-fieldset > input[type="email"], .cp-signin-login .horizontal-fieldset > input[type="password"], .cp-signin-login .login_dateStamp > .login_month, .cp-signin-login .ddmmyyyy > .login_date {
		margin-left: 0;
	}
	.login_dateStamp > legend {
		padding-bottom: 0;
	}
}
@media (max-width: 399px) {
	.cp-signin-login .login_dateStamp > .label-stack  {
		margin-left: 0;
	}
	.cp-signin-login .login_dateStamp > .login_month, .cp-signin-login .login_dateStamp > .login_date {
		padding-bottom: 0;
	}
}
@media (min-width: 400px) {
	.login_month, .login_date, .login_year {
		width: auto;
	}
	.login_month > select {
		min-width: 77px;
	}
	.login_date > select {
		min-width: 60px;
	}
	.login_year > select {
		min-width: 65px;
	}
	.cp-signin-login .ddmmyyyy > .login_month {
		margin-left: 10px;
	}
}
@media (max-width: 920px) {
	.cp-signin-login .login_dateStamp > legend {
		top: 17px;
	}
	.cp-signin-login .login_dateStamp .label-stack:first-of-type {
		padding-top: 1.1em;
	}
	.cp-signin-login .login_dateStamp .label-stack:first-of-type > label {
		top: 1.75em;
	}
}
@media (min-width: 920px) {
	.cp-signin-login .horizontal-field > label, .cp-signin-login .horizontal-fieldset > legend {
		text-align: right;
		width: 110px;
	}
	.cp-signin-login .horizontal-field > input[type="text"], .cp-signin-login .horizontal-field > input[type="email"], .cp-signin-login .horizontal-field > input[type="password"] {
		width: 225px;
	}
	.cp-signin-login .horizontal-fieldset > legend {
		float: left;
		top: 18px;
	}
	.cp-signin-login .form-field-msg + .form-field-msg {
		margin-left: .5em;
	}
	.cp-signin-login .form-field-msg, .cp-signin-login .form-field > .form-field-info {
		margin-left: 110px; 
		padding-left: 12px;
	}
	.cp-signin-login .login_dateStamp > .login_month, .cp-signin-login .ddmmyyyy > .login_date {
		margin-left: 11px;
	}
	.cp-signin-login .label-align {
		padding-left: 121px;
	}
}
.login_month > label, .login_date > label, .login_year > label {
	width: auto;
	text-align: left;
}
/* booking list after sign in */
.booking-list li {
	overflow: hidden;
}
.booking-list li + li {
	margin-top: 2em;
}
.booking-list a {
	display: block;
}
.booking-list .embark, .booking-list .booking-nu {
	display: inline-block;
	white-space: nowrap;
}
.booking-list .embark {
	margin-right: 10%; 
}

/* 
 * Terms & Conditions / Passage Contract 
 */
#agree-tc-privacy .form-field, #agree-pax-contract .form-field {
	padding-left: 1.5em;
}
.scrolling-tc, .scrolling-pc {
	height: 200px;
	margin-bottom: 2em;
	overflow-y: scroll;
	max-width: 100%;
}
@media print {
	.scrolling-tc, .scrolling-pc {
		height: auto;
		overflow-y: auto;
		border: none;
	}
	.lt-ie8 .scrolling-tc, .lt-ie8 .scrolling-pc {
		display: inline;
	}
	.lt-ie8 .scrolling-tc .cruise-table, .lt-ie8 .scrolling-pc .cruise-table  {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	
}
.scrolling-tc > p:first-child, .scrolling-pc > p:first-child {
	margin-top: 0;
}

/*
 * Cruise table used in CMS content (passage contract and faq)
 */
.cruise-table {
	width: 100%;
}
.cruise-table th {
    background: var(--neutral-cool-light);
	font-weight: 700;
}
.cruise-table th, .cruise-table td {
	border: 1px solid var(--neutral-light);
	padding: .25em .75em;
}
.scrolling-pc .cruise-table {
	width: 85%;
}
.scrolling-pc .cruise-table tr > td:first-child, .scrolling-pc .cruise-table tr > th:first-child {
    width: 250px;
}


/* 
 * Share the savings
 */
.sts-list {
	width: 100%;
}
.sts-list td {
	vertical-align: middle;
}
.sts-list .cancel {
    width: 80px;
}
.sts-invite .horizontal-field > input[type="text"], .sts-invite .horizontal-field > select {
	width: 100%;
}
.sts-invite .msg {
	display: block;
	padding: 1em 0 0;
	white-space: nowrap;
}
@media (min-width: 600px) {
	.sts-invite .horizontal-field > label {
		text-align: right;
		width: 105px;
	}
	.sts-invite .horizontal-field > input[type="text"], .sts-invite .horizontal-field > select {
		min-width: 210px;
		width: 60%;
	}
	.sts-invite .label-align, .sts-invite .form-field-msg {
		padding-left: 117px;
	}
}


/* 
 * Air
 */
/* Transfers on Air page */
.pax-transfer {
	margin: 0;
}
.pax-transfer > p {
	margin-top: .5em;
}
.pax_pre-cruise > select, .pax_post-cruise > select {
	width: 100%;
}
#pax-transfer-btn, #pay-transfer-btn {
	margin-left: 10px;
}
.save-transfer-form #pay-transfer-btn, .mr-active #pay-transfer-btn {
	display: none;
}
#pax-transfer-msg {
	display: block;
	margin-top: 10px;
}
.saving {
	color: var(--neutral-black);
	display: none;
	font-weight: 700;
	font-size: 1.0769em;
	line-height: 28px;
	margin-top: 3px;
	margin-bottom: 3px;
}
.saving > img {
	margin-right: 3px;
	width: 28px;
	height: 28px;
	vertical-align: middle;
}
/*eZAir Bookings*/
.pax-info-req {
	background: transparent;
	margin: 1em 0;
}
.pax-info-req .pax-name {
	font-weight: 700;
	padding-left: 0;
	vertical-align: middle;
}
.pax-info-req .button {
	padding-left: 2em;
	padding-right: 2em;
}
.air-booking + .air-booking {
	margin-top: 30px;
}
.air-booking-nu, .cruise-booking-nu {
	font-weight: 400;
	font-size: .8125em;
	margin-top: 3px;
	white-space: nowrap;
}
@media (min-width: 350px) {
	.air-booking-nu, .cruise-booking-nu {
		display: block;
		float: right;
	}
	.ezair-flight-info + .air-booking-msg {
		text-align: right;
	}
}
.lt-ie8 .air-booking-nu, .lt-ie8 .cruise-booking-nu {
	margin: -25px 11px 0 0;
}
.flight-info {
	width: 100%;
}
.flight-info td, .flight-info th {
	border: none;
}
.flight-info th {
	width: 95px;
}
.air-booking-pax li + li{
	margin-top: .75em;
}
.remove-pax-name {
	margin-right: 1em;
}
.remove-pax-btn, .removing-pax {
	white-space: nowrap;
}
.remove-pax-btn > .pc-icons {
	margin-left: 0;
}
.removing-pax {
	display: none;
	font-weight: 700;
}
.removing-pax > img {
	height: 14px;
	margin-right: .25em;
	width: 14px;
	vertical-align: middle;
}
.cancel-by-booking {
	float: left;
	margin-right: 15px;
}
.cancel-by-pax {
	float: left;
	padding: 8px 0;
}
.cancel-by-pax button:hover {
	text-decoration: none;
}
.cancel-by-pax button {
	text-decoration: underline;
}
.cancel-booking-btn {
	display: block;
	margin-top: -2px;
	margin-bottom: 5px;
	padding: .5em 1.1em;	
}
.canceling-booking  {
	display: none;
	font-weight: 700;
	margin-bottom: 5px;
	padding: 0 1em;	
}
@media screen and (min-width: 640px) {
	.cancel-booking-btn, .canceling-booking {	
		float: right;
	}
}
.canceling-booking > img {
	height: 18px;
	margin-right: .25em;
	width: 18px;
	vertical-align: middle;
}
.ezair-flight-btn {
	margin: .5em .5em 1em 0;
	padding: .625em 2em;
}
.mod-flight {
	display: inline-block;
}
.lt-ie8 .ezair-flight-btn, .lt-ie8 .mod-flight {
	display: inline;
	zoom: 1;
}
.loading-ezair-flights {
	display: none;
	font-weight: 700;
	font-size: 1.2307em;
	padding: 5px 10px;
	margin-top: 16px;
}
.loading-ezair-flights > img {
	vertical-align: middle;
	margin-right: .5em;
}
.MFP-sig-terms .mfp-iframe-holder .mfp-content {
	max-width: 715px;
	min-height: 570px;
}
.MFP-sig-terms .mfp-iframe-holder .mfp-close-wrapper {
	margin: 0 auto;
	max-width: 715px;
}
.MFP-sig-terms iframe {
	height: 100% !important;
	width: 100% !important;
}
/*Independent Air*/
.pax-flight-info {
	margin-bottom: 1.5em;
	width: 100%;
}
/*Pax flight information table*/
.pax-flight-info th {
	white-space: nowrap;
}
.pax-flight-info .pc-icons {
	margin: 0;
}
.deleting-flight-info {
	display: none;
}
.deleting-flight-info > img {
	width: 26px;
	height: 26px;
	vertical-align: middle;
}
.enter-flight-info legend {
	padding: 0 15px;
}
.lt-ie8 .enter-flight-info legend {
	padding: 0 8px;
}
.enter-flight-info .form-field {
	width: 100%;
}
.enter-flight-info input[type="text"], .enter-flight-info select {
	width: 100%;
}
.enter-flight-info .air-depart, .enter-flight-info .air-depart-month, .enter-flight-info .air-depart-date, .enter-flight-info .air-depart-year {
	width: auto;
}
.air-depart-month > select, .air-depart-year > select {
	width: 78px;
}
.air-depart-date > select {
	width: 55px;
}
@media screen and (min-width: 850px) {
	.air-depart-date > select {
		width: 65px;
	}
}
.not-flying-msg {
	margin-left: 1em;
}
.enter-flight-info .saving-flight-info, .saving-not-flying {
	display: none;
	margin: .25em 0;
	font-weight: 700;
}
.saving-flight-info > img, .saving-not-flying > img {
	height: 26px;
	margin-right: .25em;
	width: 26px;
	vertical-align: middle;
}
.enter-flight-info .button + .msg {
	margin-left: 1em;
}
.ui-autocomplete {
	border: 1px solid var(--neutral-black);
}
.ui-menu .ui-menu-item a:hover {
	border: 1px solid var(--neutral-white);
}
/*legacy airport code popup*/
.airport-list {
	max-height: 400px;
	overflow-y: scroll;
}
#airport-code {
	margin: 0;
	width: 614px;
}
#airport-code td {
	color: var(--primary-blue);
}
#airport-code tr:hover td {
	background: var(--auxiliary-blue-extra-light);
	cursor: pointer;
	text-decoration: underline;
}

/* Flight Schedule Change popup styles */
#fsc-container .form-field input[type="checkbox"]{
	vertical-align: middle;
}


/*
 * Balance & Payment and Onboard Credit
 */
#grt-cc-error-msg, #ssv-cc-error-msg{
    font-family: var(--proxima-nova);
    font-weight: 100;
}
@media screen and (min-width: 640px) {
	#grt-cc-error-msg, #ssv-cc-error-msg {
		width: auto;
		margin: auto;
	}
}
@media screen and (min-width: 1000px) {
	#grt-cc-error-msg, #ssv-cc-error-msg {
		padding-left: 320px;
		padding-right: 30px;
	}
}
.invoice-payment {
	padding-top: 15px;
	padding-bottom: 15px
}
.payment-img {
	display: none;
}
.payment-img img {
	max-width: 100%;
}
@media screen and (min-width: 640px) {
	.payment-img {
		display: block;
	}
}
.shx-prepay-icon {
	background-position: -1026px -15px;
	display: inline-block;
	height: 21px;
	margin-right: 5px;
	vertical-align: middle;
	width: 36px;
}
/* price breakdown chart */
.all-charges, .invoice, .all-obc {
	width: 100%;
}
.all-charges {
	margin-bottom: 1.5em;
}
.all-charges small, .all-obc small {
	float: right;
	font-size: .8125em;
	font-weight: 400;
	margin-top: 3px;
}
.all-charges td, .all-charges th, .all-obc td, .all-obc th {
	padding: 6px 9px;
}
.all-charges td, .invoice td, .all-obc td {
	text-align: right;
}
.all-charges td, .all-obc td {
	width: 105px;
}
.all-charges .total-booking, .all-obc .total-booking {
	width: 75px;
}
.all-charges thead th, .all-obc thead th {
	font-weight: 400;
	text-align: center;
	font-size: 1em;
}
.all-charges .total-price > td, .all-charges .total-price > th, .all-obc .total-obc > td, .all-obc .total-obc > th {
	font-weight: 400;
	padding: 6px 9px;
	vertical-align: bottom;
	border-top: 2px solid var(--neutral-cool-medium);
	border-left: 1px solid var(--neutral-medium);
}
.invoice {
	background: transparent;
}
.invoice .bal-due th, .invoice .bal-due td {
	border-top: 2px solid var(--neutral-light);
	padding-top: .5em;
	padding-bottom: .5em;
}
.invoice .payment-received th, .invoice .payment-received td,
.invoice .bal-due-date th, .invoice .bal-due-date td {
	padding-bottom: .5em;
}
.invoice .bal-due-date th, .invoice .bal-due-date td {
	border-bottom: 1px solid var(--neutral-light);
}
#revise-balance-btn {
	margin-left: 20px;
	padding: .75em 1.125em;
	line-height: 1em;
}
.invoice td.th-row {
	padding-left: 0px;
	text-align: left;
}

/* see pif styles for form billing rules */
#make-payment #paymentAmount, #make-payment .payment-currency, #make-paypal-payment #paypalPaymentAmount, #make-paypal-payment .payment-currency  {
	font-size: 1.5384615em;
	font-weight: 700;
}
.input-payment-amount {
	margin: 3px 0;
	width: 100%;
}
.sm-curr-sym-indent {
	padding-left: 18px;
}
.md-curr-sym-indent {
	padding-left: 22px;
}
.lg-curr-sym-indent {
	padding-left: 26px;
}
.payment-wrapper {
	display: inline-block;
	position: relative;
	width: 100%;
}
.payment-wrapper > input[type="text"] {
	width: 100%;
}
.payment-currency {
	left: 6px; 
	line-height: 1; 
	position: absolute; 
	top: 10px;
}
.submit-payment {
	display: inline-block;
	margin-bottom: 1em;
}
.submit-payment + .form-field-info {
	display: block;
	margin-left: 0;
}

@media (min-width: 600px) {
	.payment-wrapper, .submit-payment {
		min-width: 245px;
		width: 30%;
	}
	.payment-wrapper {
		margin-left: 6px;
	}
	.submit-gift-card, .submit-payment, .message-offset , .submit-payment + .form-field-info {
		margin-left: 31.06904%;
	}
}
@media (min-width: 768px) {
	.submit-payment + .form-field-info {
		display: inline-block;
		margin-left: 6px;
	}
}

/* Shorex Pre-Pay table */
#shx-prepay-table th {
	display: table-cell;
	vertical-align: middle;
}
#shx-prepay-table th.align-left {
	text-align: left;
}

/*
 * Special Requests
 */
.download-reader {
	margin-left: 2.5em;
}


/*
 * Accessibility Requests
 */
.accessibility-request .form-field > select {
	width: 100%;
}
.accessibility-list > table {
	width: 100%;
}
.accessibility-list .ada-needed {
	width: 50%;
}
.accessibility-list .ada-cancel {
	text-align: center;
	width: 70px;
}


/*
 * Dietary Requests
 */
.dietary-request .form-field > input[type="text"], .dietary-request .form-field > input[type="email"], .dietary-request .form-field > select, .dietary-request .form-field > textarea {
	width: 100%;
}
#dietary-request-msg {
	display: inline-block; 
	max-width: 75%; 
	vertical-align: top;
}
.onboard-prefs .inline-field.radio + .inline-field.radio {
	margin-left: 1.5em;
}
.dietary-info-icon {
	width: 16px;
    height: 16px;
    border: solid 1px var(--primary-blue);
    display: inline-block;
    border-radius: 20px;
    text-align: center;
    color: var(--primary-blue);
    text-decoration: none;
    margin-left: 10px;
    line-height: 1.2em;
}
.dietary-item + label {
	display: inline;
}

/*
 * Special Occasions
 */
.special-occasions .form-field, .special-occasions .form-field > select {
    width: 100%;
}
.special-occasion-schedule .table-responsive {
	margin-top: 0;
}
.special-occasion-schedule > table {
	width: 100%;
}
.special-occasion-schedule th, .special-occasion-schedule td {
	vertical-align: middle;
}
.special-occasion-schedule .so-date {
	width: 130px;
}
.special-occasion-schedule .so-occasion {
	width: 150px;
}
.special-occasion-schedule .so-cancel {
	text-align: center;
	width: 70px;
}
.special-occasion-schedule .cancelled-occasion {
	padding-top: 8px;
	padding-bottom: 8px;
	text-align: center;
}


/*
 * Passenger Check-in
 */
.booking-pax-list:nth-child(2n+1) {
	clear: left;
}
.booking-pax-list table {
	margin: 0;
	width: 100%;
}


/* 
 * Dining Preferences 
 */
.traditional-dining-options {
	padding: 0 20px;
}
.traditional-dining-options.deactive {
	opacity: 0.3;
	filter: alpha(opacity=30);
}
.dining-time {
	display: inline-block;
	min-width: 107px;
}
@media screen and (max-width: 400px) {
	.traditional-dining-options .inline-field {
		display: block;
		margin-left: 0;
	}
}


/*
 * Onboard Documents Language Preference
 */
.pax-lang {
	display: block;
}
.pax-lang-pref > select {
	width: 100%;
}


/*
 * Travel Preferences
 */
.travel-pref-opt {
	background: transparent;
	width: 100%;
}
.travel-pref-opt th, .travel-pref-opt td {
	border: none;
	vertical-align: middle;
}
.travel-pref-opt .amenities {
	width: 40%;
}
.travel-pref-opt .very-interested, .travel-pref-opt .neutral,  .travel-pref-opt .not-interested {
	width: 20%;
}
.travel-pref-opt thead th, .travel-pref-opt td {
	text-align: center;
}
.travel-pref-opt .form-field {
	margin: 0;
	padding: 0;
}
.travel-pref-opt .form-field input {
	float: none;
	margin: auto;
}
.travel-pref-opt tbody tr:hover > th, .travel-pref-opt tbody tr:hover > td {
	background: var(--neutral-white);
}
.travel-pref-opt tbody tr td:hover {
	background: var(--auxiliary-blue-extra-light);
	cursor: pointer;
}
#travel-prefs .col-1of5:nth-child(2n+1) {
	clear: left;
	margin-left: 0;
}
#travel-prefs .col-1of5.new-line {
	clear: left;
	margin-left: 0;
}

/*
 * Downloads & Pre-Cruise Emails
 */
.download-list, .email-list {
	list-style: none;
	padding: 0;
}
.email-list ul {
	list-style: disc;
	margin: .5em 0 1em 1em;
}
.download-list > li + li, .email-list > li + li{
	margin-top: 2em;
}
#popupContent .download-list > li + li {
	margin-top: .5em;
}
.download-list a.font-weight-400, .email-list a.font-weight-400 {
	font-weight: 400;
}
.download-list a:hover, .email-list a:hover {
	text-decoration: underline;
}
.download-indent a + span, .download-list a + span, .travel-pax-list a + span {
	font-style: oblique;
	margin-left: .5em;
}
#email-list > .loading {
	font-size: 1.1538em;
	font-weight: 700;
	text-align: center;
}
#email-list > .loading img {
	vertical-align: middle;
}
/* styles for .pax-list is under the onboard activity section */
.pax-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.pax-list ul > li + li {
	margin-top: .5em;
}


/*
 * Princess Vacation Protection
 */
#protection-coverage.friends-family #regular-msg, #protection-coverage.regular-msg #friends-family, #protection-coverage.regular-msg.notCommodore #commodore{
	display: none;
}
.protection-coverage-options, .pax-protection, .pax-waiver {
	background: none;
	width: 100%;
}
.protection-coverage-options {
	min-width: 735px;
}
.pax-protection, .pax-waiver {
	min-width: 704px;
}
.table-responsive .protection-coverage-options th, .table-responsive .protection-coverage-options td, 
.table-responsive .pax-protection th, .table-responsive .pax-waiver th, 
.table-responsive .pax-protection td, .pax-waiver td {
	white-space: normal;
}
.protection-coverage-options th, .protection-coverage-options td {
	padding: 9px 13px;
}
.protection-coverage-options th, .protection-coverage-options td, .pax-protection td, .pax-protection th, .pax-waiver th, .pax-waiver td {
	text-align: center;
	vertical-align: middle;
}
.protection-coverage-options tr > td:first-child, .protection-coverage-options tr > th:first-child {
	/*font-weight: 700;*/
	text-align: left;
}
.protection-coverage-options tr > td:first-child {
	min-width: 399px;
}
.protection-coverage-options .platinum, .protection-coverage-options .standard {
	width: 168px;
	background: var(--neutral-white);
}
.pax-protection .platinum, .pax-protection .standard {
	width: 160px;
}
.pax-waiver .at-ease-waiver {
	width: 280px;
}
.protection-coverage-options tbody .platinum, .pax-protection .platinum, .pax-waiver .at-ease-waiver {
	background: var(--neutral-warm-light);
}
.protection-coverage-options tbody .standard, .pax-protection .standard {
	background: var(--neutral-warm-light);
}
.pax-protection .no-protection, .pax-waiver .no-waiver {
	background: var(--neutral-cool-light);
	width: 200px;
}
.protection-coverage-options .price-per-pax {
	font-size: 1.0714em;
	font-weight: 700;
}
/*.protection-coverage-options .dark-bg a{
	color: var(--neutral-white);
	font-weight: bold;
}*/
.protection-coverage-options .light-bg {
	font-weight: bold;
	font-size: 1em;
}
.protection-coverage-options tfoot .light-bg, .protection-coverage-options tfoot tr > td:first-child {
	font-weight: 400;
}
.protection-coverage-options tfoot tr > td:first-child, .protection-coverage-options tfoot tr > td  {
	width: auto;
}
.protection-coverage-options .align-top {
	vertical-align: top;
}
.protection-coverage-options .align-top em {
	font-size: .9285em;
}
.protection-coverage-options .note {
	font-size: 0.85em
}
.protection-coverage-options .no-protection {
	width: 150px;
}
.protection-coverage-options tbody .no-protection {
	text-align: left; 
	vertical-align: middle;
}
.pax-protection th, .pax-waiver th {
	border: none;
	font-weight: 700;
	text-align: right;
	vertical-align: middle;
}
.pax-protection .radio, .pax-waiver .radio  {
	padding-left: 0;
	margin: 0;
}
.pax-protection .radio > label, .pax-waiver .radio > label {
	padding: 23px 0 9px;
	display: block;
}
.pax-protection .radio > input, .pax-waiver .radio > input {
	left: 50%;
	position: absolute;
	margin-left: -7px;
	top: 10px;
}
.pax-protection .price, .pax-waiver .price {
	font-weight: 700;
}
.pax-protection .upgrade {
	padding: 8px 7px;
	display: inline-block;
}
.pax-protection del, .pax-protection ins {
	padding: 0 .25em;
}
.pax-protection del {
	color: var(--neutral-cool-medium);
}
.pax-protection ins {
	background: none;
	font-weight: 700;
}
.pax-protection .not-selected > label, .pax-waiver .not-selected > label {
	opacity: .3;
	filter:alpha(opacity=30);
}
.lt-ie8 .not-selected > label, .lt-ie8 .not-selected > label, .lt-ie8 .not-selected .price {
	color: var(--neutral-medium);
}
.complimentary-msg {
	color: var(--auxiliary-green);
}
.protection-waiver {
	margin: 0 15px;
}
.at-ease-payment {
	margin-left: 0;
	max-width: 640px;
	width: 100%;
}
.at-ease-payment th {
	background: var(--auxiliary-blue-extra-light);
}
.at-ease-payment th, .at-ease-payment td {
	width: 50%
}


/*
 * Itinerary
 */
@media screen and (min-width: 640px) {
	.itin-reserved-for {
		float: left;
		margin-bottom: .1em;
		margin-right: .5em;
	}
}
.itin-name {
	white-space: nowrap;
}
.notices {
	padding: 10px 10px 0;
} 
.notices + .notices {
	margin-top: 2em;
}
.special-request-list {
	list-style: none;
}
.itin-title {
	padding: .75em 0 .5em;
	margin: 0;
}
.itin-img {
	text-align: center;
}
.itin-img > img {
	margin: 0 auto;
}
.itin {
	width: 100%;
	margin: 1.5em 0 0;
}
.itin, .itin th, .itin td {
	border: 0 none;
}
.itin td {
	border-top: 1px solid var(--neutral-white);
	border-bottom: 1px solid var(--neutral-white);
}
.itin .date {
	width: 60px;
}
.itin .duration {
	width: 65px;
}
.itin .duration-spacer, .itin .depart-time, .itin .arrive-time {
	display: block;
	text-align: center;
	width: 100%;
}
.itin-fb-share {
	position: absolute !important;
	top: 10px;
	right: 90px;
}
@media screen and (max-width: 479px) {
	.itin th, .itin td {
		padding: .25em .25em;
	}
}
@media screen and (min-width: 480px) {
	.itin .date {
		width: 130px;
		white-space: nowrap;
	}
	.itin .duration {
		width: 110px;
	}	
}
@media screen and (min-width: 640px) {
	.itin .duration {
		width: 250px
	}
	.itin .depart-time, .itin .arrive-time {
		display: inline-block;
		min-width: 90px;
		width: auto;
	}
	.itin .depart-time {
		text-align: left;
	}
	.itin .arrive-time {
		text-align: right;
	}
	.itin .duration-spacer {
		display: inline-block;
		width: 15px;
	}
}
.itin .landex > td {
	background: var(--auxiliary-green-light);
}
.itin .shorex > td {
	background: var(--auxiliary-blue-light);
}
.itin .at-sea > td {
	background: var(--neutral-light);
}
.js .itin-detail {
	display: none;
}
.itin .capitalize {
	text-transform: capitalize;
}
.view-itin-detail {
	position: relative;
	padding-left: 22px;
	overflow: visible;
}
.view-itin-detail > span {
	border: 1px solid var(--primary-blue);
	bottom: 0;
	display: block;
	left: 0;
	line-height: 1;
	padding: 1px 0 0;
	overflow: hidden;
	position: absolute;
	text-align: center;
	width: 14px;
}
.lt-ie8 .view-itin-detail > span {
	padding: 0 0 4px;
}
.view-itin-detail > span:hover {
	text-decoration: none;
}
.flight-itin h3 > span {
	padding-right: .5em;
}
@media print {
	#content .itin a:after {
		content: "";
	}
	#itin-print, .view-itin-detail {
		display: none;
	}
	.select-pax > input {
		position: static;
	}
}


/*
 * PIF
 */
.label-stack.populated.pif_month>label, .label-stack.populated.pif_date>label, .label-stack.populated.pif_year>label {
	border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
	visibility: visible;
}

#pif form {
	padding-bottom: 15px;
}
.pif_form p:first-child {
	margin-top: 0;
}
.pif_form .horizontal-field > input[type="text"], .pif_form .horizontal-field > input[type="email"], .pif_form .horizontal-field > input[type="tel"], .pif_form .horizontal-field > select, .pif_form .horizontal-field > textarea, .pif_month, .pif_date, .pif_year, .pif_month > select, .pif_date > select, .pif_year > select,
.make-payment .horizontal-field > input[type="text"], .make-payment .horizontal-field > input[type="email"], .make-payment .horizontal-field > select, .cc_month, .cc_year, .cc_month > select, .cc_year > select, .pif_youth .option-group {
	width: 100%;
}
.pif_form .btn-center, .pif_form .next-step, .pif_form .msg {
	margin: 15px 0;
	text-align: center;
}
.accordion-title > .pif_name {
	display: none;
	font-weight: 400;
	white-space: nowrap;
}
.selected .pif_name {
	display: inline;
}
.ccType > .pc-icons, .ccType > .cp-card-icons {
	margin: .3076923em .3846153em .3076923em .3846153em;
}
.ccType > .jcb-24, .ccType > .unionpay-24 {
	background: url(/images/personalizer/cc-icons.png) no-repeat transparent;
    direction: ltr;
    display: inline-block;
    overflow: hidden;
    text-align: left;
    text-indent: -999em;
    vertical-align: middle;
    margin: 0 .35em;
}
.ccType > .jcb-24 {
	width: 38px;
	height: 24px;
	background-position: 0px 0px;
}
.ccType > .unionpay-24 {
	width: 38px;
	height: 24px;
	background-position: -45px 0px;
}
.pif_youth .horizontal-field > label, .pif_youth .horizontal-field > textarea,  .pif_youth .horizontal-field > .form-field-info {
	vertical-align: top;
}
.pif_form .horizontal-field > textarea {
	min-height: 100px;
}
.pif_signInOut, .pif_sunscreen, .pif_firstaid {
	margin: 10px 0;
}
.pif_youth .option-group {
	display: inline-block;
}
.pif_youth .option-group > strong {
	display: inline-block;
	min-width: 65px;
}

@media (max-width: 390px) {
	.pif_arcQuestion {
		padding-top: 2.4615384em;
	}
}
@media (max-width: 399px) {
	.pif_form .pif_dateStamp > .pif_month, .pif_form .pif_dateStamp > .pif_date, .pif_form .pif_dateStamp > .pif_year, .make-payment .cc_dateStamp > .cc_month, .make-payment .cc_dateStamp > .cc_year {
		margin-left: 0;
	}
	.pif_form .pif_dateStamp > .pif_month, .pif_form .pif_dateStamp > .pif_date, .make-payment .cc_dateStamp > .cc_month {
		padding-bottom: 0;
	}
}
@media (max-width: 599px) {
	.pif_youth .option-group, .tel-group {
		padding-top: 10px;
	}
	.pif_form .horizontal-fieldset > legend, .make-payment .horizontal-fieldset > legend {
		padding-bottom: 0;
	}
	.pif_form .horizontal-fieldset > legend + div, .make-payment .horizontal-fieldset > legend + div {
		margin-top: 1em;
	}
	.pif_form .pif_dateStamp > .pif_month, .pif_form .ddmmyyyy > .pif_date, .pif_form .ccType > .label + .pc-icons, .make-payment .ccType > .label + .pc-icons, .make-payment .cc_dateStamp > .cc_month {
		margin-left: 0;
	}
	.pif_form .ccType > .label, .make-payment .ccType > .label, .label-spacing {
		display: block;
		text-align: left;
	}
	.label-spacing.ls-inline-block {
		display: inline-block;
	}
}
@media (min-width: 400px) {
	.pif_month, .pif_date, .pif_year, .cc_month, .cc_year {
		width: auto;
	}
	.pif_month > select, .cc_month > select {
		min-width: 100px;
		width: 100px;
	}
	.pif_date > select {
		min-width: 60px;
	}
	.pif_year > select, .cc_year > select, .pif_year > input[type=text] {
		min-width: 65px;
		width: 65px;
	}
	.pif_year > input[type=text] {
		width: 68px;
		padding-left: 5px;
	}
	.pif_form .ddmmyyyy > .pif_month {
		margin-left: 10px;
	}
}
@media (min-width: 600px) {
	.ccType > .pc-icons {
		margin: .3076923em .4615384em .3076923em .4615384em;
	}
	.pif_form .ccType > .label, .make-payment .ccType > .label, .label-spacing {
		display: inline-block;
	}
	.pif_form .horizontal-field > label, .pif_form .ccType > .label, .pif_form .horizontal-fieldset > legend, .make-payment .horizontal-field > label, .make-payment .ccType > .label, .make-payment .horizontal-fieldset > legend, .label-spacing {
		text-align: right;
		min-width: 135px;
		width: 30%;
	}
	.pif_form .tel-group label {
		min-width: 50px;
		width: auto;
	}
	.pif_form .horizontal-fieldset > legend, .make-payment .horizontal-fieldset > legend {
		float: left;
	}
	.pif_form .horizontal-field > input[type="text"], .pif_form .horizontal-field > input[type="tel"], .pif_form .horizontal-field > input[type="email"], .pif_form .horizontal-field > textarea, .make-payment .horizontal-field > input[type="text"], .make-payment .horizontal-field > input[type="email"], .pif_form .horizontal-field > select, .make-payment .horizontal-field > select, .pif_youth .option-group {
		min-width: 245px;
		width: 30%;
	}
	.pif_form .tel-group input[type="text"], .pif_form .tel-group input[type="tel"] {
		min-width: 183px;
		width: 34%;
	}
	.sms-opt-in label {
		min-width: 230px;
		width: 40%;
	}
	.pif_form .horizontal-field > textarea {
		min-height: 180px;
	}
	.pif_futureDest > .row, .pif_pastDest > .row {
		float: left;
		width: 70%;
	}
	.pif_youth .option-group {
		margin-left: 15px;
	}
	.pif_form .form-field-msg + .form-field-msg, .make-payment .form-field-msg + .form-field-msg {
		margin-left: .5em;
	}
	.pif_form .form-field-msg, .pif_form .form-field-help, .inline-fieldset > .form-field-info, .pif_form .form-field > .form-field-info,
	.make-payment .form-field-msg, .make-payment .form-field-help, .make-payment .form-field > .form-field-info, .label-offset {
		margin-left: 33%; 
		padding-left: 12px;
	}
	#pif-gdpr-form .form-field-msg {
		margin-left: 0px;
	}
	.tel-group .form-field-help {
		margin-left: 50px;
	}
	.checkbox.label-offset {
		margin-left: 31.25%;
	}
	.pif_addressForAllPax, .pif_applyAddressToAllPax, .pif_form .pif_noMiddleName, .pif_noAlienExpDate, .sms-opt-in {
		margin-left: 32.25%; 
	}
	.pif_dateStamp > legend, .pif_ccExpDate > legend, .cc_dateStamp > legend {
		top: 18px;
	}
	.pif_futureDest > legend, .pif_pastDest > legend {
		top: 6px;
	}
	.pif_form .pif_dateStamp > .pif_month, .pif_form .ddmmyyyy > .pif_date, .make-payment .cc_dateStamp > .cc_month, .pif_form .pif_dateStamp > .aidExpMonth {
		margin-left: 11px;
	}
	.pif_on-file {
		padding: 18px 0;
		margin-left: 6px;
	}
	#acctCardNumber.mcAlign, #acctCardNumber-2.mcAlign {
		margin-top: -25px;
	}
}
@media (min-width: 768px) {
	.inline-fieldset > .form-field-info, .pif_form .form-field > .form-field-info, .pif_form .pif_noMiddleName, .make-payment .form-field > .form-field-info {
		margin-left: .5em;
		padding-left: 0;
		max-width: 30%;
	}
	.pif_form .btn-row {
		position: relative;
		min-height: 45px;
		margin-top: 30px;
	}
	.pif_form  .btn-center {
		position: absolute;
		text-align: center;
		width: 220px;
		left: 50%;
		margin: 0 0 0 -110px;
	}
	.pif_form .next-step, .pif_form .msg {
		margin: 0;
		padding: 12px 15px;
	}
	.pif_form .next-step {
		float: left;
		position: relative;
		text-align: right;
		width: 37%;
	}
	.pif_form .msg {
		float: right;
		position: relative;
		text-align: left;
		width: 37%;
	}
	.pif_form .horizontal-field > textarea {
		min-height: 125px;
	}
}
@media (min-width: 940px) {
	.inline-fieldset > .form-field-info, .pif_form .form-field > .form-field-info, .pif_form .pif_noMiddleName {
		max-width: 36%;
	}
}
.pif_month > label, .pif_date > label, .pif_year > label, .pif_arcQuestionOpt > label, .pif_pastDest label, .pif_futureDest label, .pif_addressForAllPax > label, .pif_applyAddressToAllPax > label, .form-field.label-offset > label {
	width: auto;
	text-align: left;
}
.pif_form .pif_noMiddleName > label {
	text-align: left;
	width: auto;
}
.pif_form label > .required, .pif_form legend .required {
	display: none;
}
.pif_form .required-field .required, .pif_form .required-fieldset .required {
	display: inline;
}
#uk-emergency-info label {
	vertical-align: middle;
}
.pif_docNu > .form-field-info, .pif_docIssueDate > .form-field-info {
	vertical-align: top;
}
.pif_docIssueDate > .form-field-info {
	margin-top: 10px;
	margin-bottom: 10px;
}
.js #pastDest_wrapper {
	display: none;
}
/*document popup*/
.doc-popup ol ol, .doc-popup ol ul {
	margin: .25em 0;
}
.doc-popup ol > li + li {
	margin-top: .5em;
}
/* Post Cruise Pop Examples (in pif travel docs) */ 
.post-cruise-ex {
	margin: 2.5em auto;
	width: 100%;
}
.post-cruise-ex th {
	font-weight: 700;
	width: 160px;
	text-align: right;
}

/* More checkin page styles at the end of the file*/

/* 
 * FAQ - for the faq pages, I'm using the existing markup from CMS until section is redesign to be more responsive
 */
.faq-pg .return-to-faq {
	display: none;
	visibility: hidden;
}
.css-table + .css-table {
	margin-top: 15px;
 }
.css-table > div {
	overflow: hidden;
 }
.css-table h4 {
	font-size: 1.1538461em;
}
.iefloatleft {
	float: left;
	position: relative;
}
.image90 {
	width: 90px;
}
.image90 + .iefloatleft {
	float: none;
	margin-left: 105px;
}
.css-table a {
	display: inline-block;
	padding-top: 5px;
}
@media (max-width: 639px) {
	.left .col {
		border-bottom: 0;
	} 
	.right .col {
		border-top: 0;
	} 
}
.drawer {
	padding-bottom: 15px;
}
.drawer .trigger {
    background: url(/images/global/sprite_drawer-arrows.svg) no-repeat -136px -134px;
    border-top: 1px solid var(--neutral-light);
	color: var(--primary-blue);
    cursor: pointer;
    font-size: 1.1538461em;
    margin-top: 0;
    padding: 5px 5px 5px 30px;
}
.no-svg .drawer .trigger {
   background: url(/images/global/sprite_drawer-arrows.png) no-repeat -136px -134px;
}
.drawer .trigger:hover {
	background-color: var(--auxiliary-blue-extra-light);
}
.drawer .trigger:first-of-type {
	border-top: none;
}
#popupContent.drawer {
	margin: 15px 0;
}
.drawer .active {
	background-position: -136px -34px;
}
.drawer .toggle_container {
	background-color: var(--neutral-light);
	padding: 0 15px;
}
.js .drawer .toggle_container {
	display: none;
}
.drawer .content-bg {
	display: block;
	background: rgb(226,226,226); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2UyZTJlMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmOGY4ZjgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  rgba(226,226,226,1) 0%, rgba(248,248,248,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(226,226,226,1)), color-stop(100%,rgba(248,248,248,1))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(226,226,226,1) 0%,rgba(248,248,248,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(226,226,226,1) 0%,rgba(248,248,248,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(226,226,226,1) 0%,rgba(248,248,248,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(226,226,226,1) 0%,rgba(248,248,248,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--neutral-light)', endColorstr='var(--neutral-light)',GradientType=0 ); /* IE6-8 */
	margin: 0 -15px;
}
.drawer .content-bg + p {
	margin-top: .25em;
}
.css-table .tour_alaska, .css-table .tour_asia, .css-table .tour_australia, .css-table .tour_canada_new_england, .css-table .tour_europe, .css-table .tour_south_america {
	display: none;
	visibility: hidden;
}
.a-tour .tour_alaska, .o-tour .tour_asia, .s-tour .tour_south_america, .e-tour .tour_europe, .n-tour .tour_canada_new_england, .p-tour .tour_australia {
	display: inline-block;
	visibility: visible;
}

/*
 *	Styles to override .mfp-helper class attached to <html>
 *	to fix the positioning of lightbox opened from an open
 *	accordion.
 */
html.mfp-helper body{
	-webkit-transform:none;
}


/*
 * Header Background images
 */

 #container.header-bg {
	background-attachment: inherit;
}



/*
 * Full-width CP overides
 */
@media (min-width: 959px){
	.full-width .small-spacing .article-title, .full-width .medium-spacing .article-title, .full-width .large-spacing .article-title {
		top: 48px;
	}
	.full-width.cp-bg .small-spacing .article-title, .full-width.cp-bg .medium-spacing .article-title, .full-width.cp-bg .large-spacing .article-title {
		top: 0px;
	}
}

/*
 * Background image for inner pages CP override
 * TODO: Should be removed once the inner pages are updated to the new template
 */
/* Destination background images - there are two versions of background images. Full-bg is used in fnc and polar bear, where header-bg is used in cp and other parts of the site, */
#container.full-bg, #container.header-bg {
	background-image: none;
	background-attachment:fixed;
	background-repeat: no-repeat;
}
#container.full-bg {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;	
}
#container.header-bg {
	background-position: 50% 0%;
}
#container.header-bg.a {
	background-image: url("/images/global/destinations/background_header/personalizer/a_bg_768.jpg"); 
}
#container.header-bg.o {
	background-image: url("/images/global/destinations/background_header/personalizer/o_bg_768.jpg"); 
}
#container.header-bg.z {
	background-image: url("/images/global/destinations/background_header/personalizer/z_bg_768.jpg"); 
}
#container.header-bg.l {
	background-image: url("/images/global/destinations/background_header/personalizer/l_bg_768.jpg"); 
}
#container.header-bg.n {
	background-image: url("/images/global/destinations/background_header/personalizer/n_bg_768.jpg"); 
}
#container.header-bg.c {
	background-image: url("/images/global/destinations/background_header/personalizer/c_bg_768.jpg"); 
}
#container.header-bg.e {
	background-image: url("/images/global/destinations/background_header/personalizer/e_bg_768.jpg"); 
}
#container.header-bg.r {
	background-image: url("/images/global/destinations/background_header/personalizer/r_bg_768.jpg"); 
}
#container.header-bg.v {
	background-image: url("/images/global/destinations/background_header/personalizer/v_bg_768.jpg"); 
}
#container.header-bg.h {
	background-image: url("/images/global/destinations/background_header/personalizer/h_bg_768.jpg"); 
}
#container.header-bg.k {
	background-image: url("/images/global/destinations/background_header/personalizer/k_bg_768.jpg"); 
}
#container.header-bg.m {
	background-image: url("/images/global/destinations/background_header/personalizer/m_bg_768.jpg"); 
}
#container.header-bg.t {
	background-image: url("/images/global/destinations/background_header/personalizer/t_bg_768.jpg"); 
}
#container.header-bg.j {
	background-image: url("/images/global/destinations/background_header/personalizer/j_bg_768.jpg"); 
}
#container.header-bg.s {
	background-image: url("/images/global/destinations/background_header/personalizer/s_bg_768.jpg"); 
}
#container.header-bg.i {
	background-image: url("/images/global/destinations/background_header/personalizer/i_bg_768.jpg"); 
}
#container.header-bg.f {
	background-image: url("/images/global/destinations/background_header/personalizer/f_bg_768.jpg"); 
}
#container.header-bg.w {
	background-image: url("/images/global/destinations/background_header/personalizer/w_bg_768.jpg"); 
}
#container.header-bg.a-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/a_tour_bg_768.jpg"); 
}
#container.header-bg.o-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/o_tour_bg_768.jpg"); 
}
#container.header-bg.z-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/z_tour_bg_768.jpg"); 
}
#container.header-bg.l-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/l_tour_bg_768.jpg"); 
}
#container.header-bg.n-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/n_tour_bg_768.jpg"); 
}
#container.header-bg.c-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/c_tour_bg_768.jpg"); 
}
#container.header-bg.e-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/e_tour_bg_768.jpg"); 
}
#container.header-bg.r-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/r_tour_bg_768.jpg"); 
}
#container.header-bg.v-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/v_tour_bg_768.jpg"); 
}
#container.header-bg.h-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/h_tour_bg_768.jpg"); 
}
#container.header-bg.k-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/k_tour_bg_768.jpg"); 
}
#container.header-bg.m-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/m_tour_bg_768.jpg"); 
}
#container.header-bg.t-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/t_tour_bg_768.jpg"); 
}
#container.header-bg.j-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/j_tour_bg_768.jpg"); 
}
#container.header-bg.s-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/s_tour_bg_768.jpg"); 
}
#container.header-bg.i-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/i_tour_bg_768.jpg"); 
}
#container.header-bg.f-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/f_tour_bg_768.jpg"); 
}
#container.header-bg.w-tour {
	background-image: url("/images/global/destinations/background_header/personalizer/w_bg_768.jpg"); 
}

@media (min-width: 768px) {
	#container.header-bg.a {
		background-image: url("/images/global/destinations/background_header/personalizer/a_bg_1100.jpg"); 
	}
	#container.header-bg.o {
		background-image: url("/images/global/destinations/background_header/personalizer/o_bg_1100.jpg"); 
	}
	#container.header-bg.z {
		background-image: url("/images/global/destinations/background_header/personalizer/z_bg_1100.jpg"); 
	}
	#container.header-bg.l {
		background-image: url("/images/global/destinations/background_header/personalizer/l_bg_1100.jpg"); 
	}
	#container.header-bg.n {
		background-image: url("/images/global/destinations/background_header/personalizer/n_bg_1100.jpg"); 
	}
	#container.header-bg.c {
		background-image: url("/images/global/destinations/background_header/personalizer/c_bg_1100.jpg"); 
	}
	#container.header-bg.e {
		background-image: url("/images/global/destinations/background_header/personalizer/e_bg_1100.jpg"); 
	}
	#container.header-bg.r {
		background-image: url("/images/global/destinations/background_header/personalizer/r_bg_1100.jpg"); 
	}
	#container.header-bg.v {
		background-image: url("/images/global/destinations/background_header/personalizer/v_bg_1100.jpg"); 
	}
	#container.header-bg.h {
		background-image: url("/images/global/destinations/background_header/personalizer/h_bg_1100.jpg"); 
	}
	#container.header-bg.k {
		background-image: url("/images/global/destinations/background_header/personalizer/k_bg_1100.jpg"); 
	}
	#container.header-bg.m {
		background-image: url("/images/global/destinations/background_header/personalizer/m_bg_1100.jpg"); 
	}
	#container.header-bg.t {
		background-image: url("/images/global/destinations/background_header/personalizer/t_bg_1100.jpg"); 
	}
	#container.header-bg.j {
		background-image: url("/images/global/destinations/background_header/personalizer/j_bg_1100.jpg"); 
	}
	#container.header-bg.s {
		background-image: url("/images/global/destinations/background_header/personalizer/s_bg_1100.jpg"); 
	}
	#container.header-bg.i {
		background-image: url("/images/global/destinations/background_header/personalizer/i_bg_1100.jpg"); 
	}
	#container.header-bg.f {
		background-image: url("/images/global/destinations/background_header/personalizer/f_bg_1100.jpg"); 
	}
	#container.header-bg.w {
		background-image: url("/images/global/destinations/background_header/personalizer/w_bg_1100.jpg"); 
	}
	#container.header-bg.a-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/a_tour_bg_1100.jpg"); 
	}
	#container.header-bg.o-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/o_tour_bg_1100.jpg"); 
	}
	#container.header-bg.z-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/z_tour_bg_1100.jpg"); 
	}
	#container.header-bg.l-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/l_tour_bg_1100.jpg"); 
	}
	#container.header-bg.n-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/n_tour_bg_1100.jpg"); 
	}
	#container.header-bg.c-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/c_tour_bg_1100.jpg"); 
	}
	#container.header-bg.e-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/e_tour_bg_1100.jpg"); 
	}
	#container.header-bg.r-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/r_tour_bg_1100.jpg"); 
	}
	#container.header-bg.v-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/v_tour_bg_1100.jpg"); 
	}
	#container.header-bg.h-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/h_tour_bg_1100.jpg"); 
	}
	#container.header-bg.k-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/k_tour_bg_1100.jpg"); 
	}
	#container.header-bg.m-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/m_tour_bg_1100.jpg"); 
	}
	#container.header-bg.t-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/t_tour_bg_1100.jpg"); 
	}
	#container.header-bg.j-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/j_tour_bg_1100.jpg"); 
	}
	#container.header-bg.s-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/s_tour_bg_1100.jpg"); 
	}
	#container.header-bg.i-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/i_tour_bg_1100.jpg"); 
	}
	#container.header-bg.f-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/f_tour_bg_1100.jpg"); 
	}
	#container.header-bg.w-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/w_bg_1100.jpg"); 
	}
}

@media (min-width: 960px) {
	#container.header-bg.a {
		background-image: url("/images/global/destinations/background_header/personalizer/a_bg_1600.jpg"); 
	}
	#container.header-bg.o {
		background-image: url("/images/global/destinations/background_header/personalizer/o_bg_1600.jpg"); 
	}
	#container.header-bg.z {
		background-image: url("/images/global/destinations/background_header/personalizer/z_bg_1600.jpg"); 
	}
	#container.header-bg.l {
		background-image: url("/images/global/destinations/background_header/personalizer/l_bg_1600.jpg"); 
	}
	#container.header-bg.n {
		background-image: url("/images/global/destinations/background_header/personalizer/n_bg_1600.jpg"); 
	}
	#container.header-bg.c {
		background-image: url("/images/global/destinations/background_header/personalizer/c_bg_1600.jpg"); 
	}
	#container.header-bg.e {
		background-image: url("/images/global/destinations/background_header/personalizer/e_bg_1600.jpg"); 
	}
	#container.header-bg.r {
		background-image: url("/images/global/destinations/background_header/personalizer/r_bg_1600.jpg"); 
	}
	#container.header-bg.v {
		background-image: url("/images/global/destinations/background_header/personalizer/v_bg_1600.jpg"); 
	}
	#container.header-bg.h {
		background-image: url("/images/global/destinations/background_header/personalizer/h_bg_1600.jpg"); 
	}
	#container.header-bg.k {
		background-image: url("/images/global/destinations/background_header/personalizer/k_bg_1600.jpg"); 
	}
	#container.header-bg.m {
		background-image: url("/images/global/destinations/background_header/personalizer/m_bg_1600.jpg"); 
	}
	#container.header-bg.t {
		background-image: url("/images/global/destinations/background_header/personalizer/t_bg_1600.jpg"); 
	}
	#container.header-bg.j {
		background-image: url("/images/global/destinations/background_header/personalizer/j_bg_1600.jpg"); 
	}
	#container.header-bg.s {
		background-image: url("/images/global/destinations/background_header/personalizer/s_bg_1600.jpg"); 
	}
	#container.header-bg.i {
		background-image: url("/images/global/destinations/background_header/personalizer/i_bg_1600.jpg"); 
	}
	#container.header-bg.f {
		background-image: url("/images/global/destinations/background_header/personalizer/f_bg_1600.jpg"); 
	}
	#container.header-bg.w {
		background-image: url("/images/global/destinations/background_header/personalizer/w_bg_1600.jpg"); 
	}
	#container.header-bg.a-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/a_tour_bg_1600.jpg"); 
	}
	#container.header-bg.o-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/o_tour_bg_1600.jpg"); 
	}
	#container.header-bg.z-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/z_tour_bg_1600.jpg"); 
	}
	#container.header-bg.l-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/l_tour_bg_1600.jpg"); 
	}
	#container.header-bg.n-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/n_tour_bg_1600.jpg"); 
	}
	#container.header-bg.c-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/c_tour_bg_1600.jpg"); 
	}
	#container.header-bg.e-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/e_tour_bg_1600.jpg"); 
	}
	#container.header-bg.r-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/r_tour_bg_1600.jpg"); 
	}
	#container.header-bg.v-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/v_tour_bg_1600.jpg"); 
	}
	#container.header-bg.h-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/h_tour_bg_1600.jpg"); 
	}
	#container.header-bg.k-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/k_tour_bg_1600.jpg"); 
	}
	#container.header-bg.m-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/m_tour_bg_1600.jpg"); 
	}
	#container.header-bg.t-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/t_tour_bg_1600.jpg"); 
	}
	#container.header-bg.j-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/j_tour_bg_1600.jpg"); 
	}
	#container.header-bg.s-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/s_tour_bg_1600.jpg"); 
	}
	#container.header-bg.i-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/i_tour_bg_1600.jpg"); 
	}
	#container.header-bg.f-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/f_tour_bg_1600.jpg"); 
	}
	#container.header-bg.w-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/w_bg_1600.jpg"); 
	}
}	

@media (min-width: 1600px) {
	#container.header-bg.a {
		background-image: url("/images/global/destinations/background_header/personalizer/a_bg_1920.jpg"); 
	}
	#container.header-bg.o {
		background-image: url("/images/global/destinations/background_header/personalizer/o_bg_1920.jpg"); 
	}
	#container.header-bg.z {
		background-image: url("/images/global/destinations/background_header/personalizer/z_bg_1920.jpg"); 
	}
	#container.header-bg.l {
		background-image: url("/images/global/destinations/background_header/personalizer/l_bg_1920.jpg"); 
	}
	#container.header-bg.n {
		background-image: url("/images/global/destinations/background_header/personalizer/n_bg_1920.jpg"); 
	}
	#container.header-bg.c {
		background-image: url("/images/global/destinations/background_header/personalizer/c_bg_1920.jpg"); 
	}
	#container.header-bg.e {
		background-image: url("/images/global/destinations/background_header/personalizer/e_bg_1920.jpg"); 
	}
	#container.header-bg.r {
		background-image: url("/images/global/destinations/background_header/personalizer/r_bg_1920.jpg"); 
	}
	#container.header-bg.v {
		background-image: url("/images/global/destinations/background_header/personalizer/v_bg_1920.jpg"); 
	}
	#container.header-bg.h {
		background-image: url("/images/global/destinations/background_header/personalizer/h_bg_1920.jpg"); 
	}
	#container.header-bg.k {
		background-image: url("/images/global/destinations/background_header/personalizer/k_bg_1920.jpg"); 
	}
	#container.header-bg.m {
		background-image: url("/images/global/destinations/background_header/personalizer/m_bg_1920.jpg"); 
	}
	#container.header-bg.t {
		background-image: url("/images/global/destinations/background_header/personalizer/t_bg_1920.jpg"); 
	}
	#container.header-bg.j {
		background-image: url("/images/global/destinations/background_header/personalizer/j_bg_1920.jpg"); 
	}
	#container.header-bg.s {
		background-image: url("/images/global/destinations/background_header/personalizer/s_bg_1920.jpg"); 
	}
	#container.header-bg.i {
		background-image: url("/images/global/destinations/background_header/personalizer/i_bg_1920.jpg"); 
	}
	#container.header-bg.f {
		background-image: url("/images/global/destinations/background_header/personalizer/f_bg_1920.jpg"); 
	}
	#container.header-bg.w {
		background-image: url("/images/global/destinations/background_header/personalizer/w_bg_1920.jpg"); 
	}
	#container.header-bg.a-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/a_tour_bg_1920.jpg"); 
	}
	#container.header-bg.o-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/o_tour_bg_1920.jpg"); 
	}
	#container.header-bg.z-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/z_tour_bg_1920.jpg"); 
	}
	#container.header-bg.l-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/l_tour_bg_1920.jpg"); 
	}
	#container.header-bg.n-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/n_tour_bg_1920.jpg"); 
	}
	#container.header-bg.c-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/c_tour_bg_1920.jpg"); 
	}
	#container.header-bg.e-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/e_tour_bg_1920.jpg"); 
	}
	#container.header-bg.r-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/r_tour_bg_1920.jpg"); 
	}
	#container.header-bg.v-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/v_tour_bg_1920.jpg"); 
	}
	#container.header-bg.h-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/h_tour_bg_1920.jpg"); 
	}
	#container.header-bg.k-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/k_tour_bg_1920.jpg"); 
	}
	#container.header-bg.m-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/m_tour_bg_1920.jpg"); 
	}
	#container.header-bg.t-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/t_tour_bg_1920.jpg"); 
	}
	#container.header-bg.j-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/j_tour_bg_1920.jpg"); 
	}
	#container.header-bg.s-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/s_tour_bg_1920.jpg"); 
	}
	#container.header-bg.i-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/i_tour_bg_1920.jpg"); 
	}
	#container.header-bg.f-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/f_tour_bg_1920.jpg"); 
	}
	#container.header-bg.w-tour {
		background-image: url("/images/global/destinations/background_header/personalizer/w_bg_1920.jpg"); 
	}
}

/*
 * Paypal implementation styles
 */

.pay-now-btn{
	min-height: 44px;
	vertical-align: top;
}
.paypal-btn{
	/*display: inline-block;
	margin-left: 15px;*/
}
.paypal-btn img{
	width: 115px;
	height: 44px;
}
.pay-btn-grp.paypal-btn img{
	width: auto;
	height: 35px;
}

/*
 * New Payment Flow styles
 */
 .no-float{
 	/*clear: both;*/
 	float: none;
 }
.blue-rounded-corner {
    border: 1px solid var(--primary-blue);
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -khtml-border-radius: 2px;
    border-radius: 3px;
}
.radio-opt{
	padding: 8px;
	display: inline-block;	
	background: var(--neutral-white);
	min-width: 85px;
	margin-right: 5px;
}
.radio-opt input{
	vertical-align: middle;	
}
/*.radio-opt input:focus{
	border: none;
}*/
.radio-opt label{
	display: inline;
	color: var(--primary-blue);
	font-weight: 700;
}
.radio-opt.selected{
	background: var(--primary-medium);
}
.radio-opt.selected label{
	color: var(--neutral-white);
	font-weight: normal;
}
.radio-opt.deactive{
	background: var(--neutral-light);
}
.radio-opt.deactive label{
	color: var(--neutral-cool-medium);
	font-weight: normal;
}
@media (min-width: 600px) {
	.make-payment .horizontal-field > label, .make-payment .ccType > .label, .make-payment .horizontal-fieldset > legend{
		width: 33.5%;
	}
	.make-payment .ccCvc > label, .make-payment .ccNumber > label {
		margin-top: -22px;
	}
}
.submit-payment{
	margin: 0px;
}

/*
 * Print styles
 */
@media print {
	#container {
		box-shadow: none;	/* box-shadow given in the global style is causing grey background on print */
	}

	#princess-header {
		display: block !important;
		min-height: 0;
	}
	.cp-shadow {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
	.cp-booking, .cp-footer {
		border: none;
		padding: 0;
	}
	.cp-header {
		min-height: 0;
		margin: 0 auto;
		max-width: none;
		padding: 0;
		width: 100%;
	}
	.cp-header > .inner-wrapper {
		height: auto;
		opacity: 1;
		transition: none;
	}
	.cp-booking-info {
		color: var(--neutral-black);
		text-align: left;
		width: 100%;
	}
	.cp-footer {
		margin: 0;
		padding: 0;
		border-top: none;
	}
	.cp-header-links, .cp-header-btns, .cp-nav, .cp-footer-btn, .cp-touch-menu {
		display: none !important;
	}
	.cp-booking .booking-number {
		display: inline;
	}
	.white {
		color: var(--neutral-black);
	}
	#itinerary{
		margin: 10px;
	}
}

/*
 * Accessibility styles & color replacements
 */

.accessibility{
	position:absolute;
	text-indent: -9999px;
}

/* Color replacements for global styles */
.orange, .optional {
	color: var(--caution);
}
.green, .accepted, .complete, .success, .onboard-credit {
	color: var(--auxiliary-green);
}
 /* Ocean button */
#ocean-btn.button {
  padding-left: .7em;
  padding-right: .7em;
}

/*
 * Background Slideshow
 */
.bg-slideshow-container {
	display: block;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
}
/*.cp-bg .bg-slideshow-container {
	top: -45px;
}*/
.bg-slideshow-container img {
	margin-left: -28.5083%;
	width: 157.01668%;
}
.full-width .bg-slideshow-container .slide-img{
	width: 100%;
}
.full-width .bg-slideshow-container .slide-img > img {
	margin-left: 0;
	width: 100%;
}
.slideshow {
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: 640px;
}
.slideshow img {
	width: 100%;
	border: 1px solid var(--neutral-cool-medium);
}
@media screen and (min-width: 768px) {
	.full-width .bg-slideshow-container .slide-img > img {
		margin-left: 0;
		width: 100%;
	}
}
@media screen and (min-width: 1020px) {
	.bg-slideshow-container {
		height: 650px;
		left: 50%;
		margin-left: -800px;
		width: 1600px;
	}
	.full-width .bg-slideshow-container {
		height: auto;
		left: auto;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}
	.bg-slideshow-container img, .bg-slideshow-container .slide-img > img {
		margin-left: 0;
		width: auto;
	}
	.full-width .bg-slideshow-container .slide-img > img {
		width: 100%;
	}
}
.bg-slideshow-row {
	position: relative;
	margin: 0 auto;
}
.bg-slideshow-row.not-ready, .slideshow-row.not-ready {
	background: url(/images/global/ajax_loader.gif) no-repeat center center;
}
.bg-slideshow-row.not-ready > .bg-slideshow-controls, .bg-slideshow-row.not-ready > .bg-slideshow-nav, 
.slideshow-row.not-ready > .slideshow-controls, .slideshow-row.not-ready > .slideshow-nav {
	display: none;
}
.bg-slideshow, .slideshow {
	overflow: hidden;
	position: relative;
	width: 100%;
}
.bg-slideshow-item, .slideshow-item {
	overflow: hidden;
	position: relative;
	padding: 0;
	text-align: center;
	width: 100%;
}
/*
.bg-slideshow-item > a {
	display: block;
	left: 15px;
	right: 15px;
	height: 54%;
	position: absolute;
	z-index: 10;
}
@media screen and (min-width: 768px) {
	.bg-slideshow-item > a {
		top: 30px;
	}
}
@media screen and (min-width: 860px) {
	.bg-slideshow-item > a {
		height: 300px;
	}
}
@media screen and (min-width: 1020px) {
	.bg-slideshow-item > a {
		max-width: 990px;
		width: 100%;
		left: 50%;
		margin-left: -495px;
		right: auto;
	}
}
*/


/* SlideshowNav Styles - "The dots" */
.bg-slideshow-nav {
	left: 50%;
	margin-left: -100px;
	position: absolute;
	top: 42%;
	text-align: center;
	width: 200px;
	z-index: 150;
}
@media (min-width: 360px) {
	.bg-slideshow-nav {
		top: 44%;
	}
}
@media (min-width: 480px) {
	.bg-slideshow-nav {
		top: 46%;
	}
}
@media (min-width: 568px) {
	.bg-slideshow-nav {
		top: 50%;
	}
}
.bg-slideshow-nav a {
	text-shadow: 2px 2px 4px var(--primary-dark);
	text-shadow: 2px 2px 4px rgba(0,0,0,.4);
	color: var(--neutral-cool-dark);
	display: inline-block;
	font-size: 2em;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
	opacity: .6;
	outline: none;
	text-decoration: none;
	-webkit-text-stroke: 1px;
}
.bg-slideshow-nav a + a {
	margin-left: 10px;
}
.bg-slideshow-nav a:focus {
	outline: none;
}
.bg-slideshow-nav a:hover {
	color: var(--neutral-medium);
	opacity: .5;
}
.bg-slideshow-nav a.activeSlide, .bg-slideshow-nav .cycle-pager-active {
	color: var(--neutral-white);
	opacity: 1;
}
.bg-slideshow-nav a::-moz-focus-inner {
	border: 0;
}

@media screen and (min-width:640px) {
	.bg-slideshow-nav a {
		font-size: 2.5em;
	}
}
@media screen and (min-width: 860px) {
	.bg-slideshow-nav {
		top: 285px;
	}
}
.slideshow-thumb-nav img, .video-thumb-link {
	cursor: pointer;
}
.slideshow-thumb-nav img, .video-thumb-link img{
	border: 2px solid var(--neutral-cool-medium);
}
.cycle-pager-active img, .video-thumb.active img {
	border: 2px solid var(--caution);	
}
.slideshow-caption {
	z-index: 200;
}

/*
 * Pax Check-in redesign: tpl-v4 cp adjustments
 */
.cp-bg {
	position: relative;
	padding:1px 0 0;
	margin-top: 0px;
}
.article-nav h2.h2-inline-header {
	display: inline;
	font-size: inherit;
	font-family: inherit;
}
.tpl-v4 #content.cp-spacing {
    margin-top: -1px;
	padding-top: 0;
}
.tpl-v4 #content.ocean-spacing {
    margin-top: -1px;
	border-top: 1px solid var(--neutral-cool-medium);
	padding-top: 0;
}
@media screen and (min-width: 48em) {
    /* 768px */
    body:not(.payment-pg) .cp-bg {
		margin-top: -5px;
	}
	.tpl-v4 #content.cp-spacing {
        margin-top: 10.069100391134%;
    }
    .tpl-v4 #content.ocean.cp-spacing {
        margin-top: 28.636363636364%;
    }
}
@media screen and (min-width: 60em) {
    /* 960px */
    .tpl-v4 #content.cp-spacing {
        margin-top: 9.411764705882%;
    }
    .tpl-v4 #content.ocean.cp-spacing {
        margin-top: 19.6875%;
    }
}

/* Checkin page styles */
.checkin-title-container {
	padding: 0.45em 0.65em;
}
.checkin-title-container.complete-bg {
	background: var(--neutral-warm-light);
}
.checkin-title-container.incomplete-bg {
	background: var(--neutral-warm-light);
}
/* CCN loyalty level icons */
.guest-level-wrapper {
	display: inline-block;
}
.guest-level-wrapper .svg-icon {
    width: 25px;
    height: 25px;
    color: var(--primary-blue-dark);
}
.guest-level-wrapper .svg-icon.gold {
    color: var(--auxiliary-orange);
}
.guest-level-wrapper .svg-icon.ruby {
    color: var(--auxiliary-red);    
}
.guest-level-wrapper .svg-icon.platinum {
    color: var(--neutral-cool-dark);    
}
.guest-level-wrapper .svg-icon.elite {
    color: var(--neutral-black);    
}
/* Guest name H2 */
.checkin-guest-title {
	padding: 0em 0.25em;
	display: inline-block;
	margin: 0px;
	vertical-align: middle;
}
.checkin-title-container .guest-age {
	margin-top: 2px;
}

/* Guest Checkin Status list */
.checkin-status-list {
	padding: 0.65em;
}
.checkin-status-list ul {
	list-style: none;
	padding: 0;
}
.checkin-status-list ul.pif-oma-checklist {
	list-style: disc;
	padding-left: 20px;
}
ul.checkin-required-list {
	margin: 10px 0;
}
@media (min-width: 860px) {
	.checkin-status-list ul {
		list-style: none;
		padding: 0 25px;
	}
}
.checkin-status-list ul li {
	padding: 5px 0;
}
.checkin-status-list li > ul {
	padding: 0 15px;
	margin: 5px 0 0 0;
}
.traveldoc-btn-container {
	padding: 0px 15px;
	margin: 0;
	height: 100px;
	display: table-cell;
	vertical-align: middle;
}
.traveldoc-btn-container .pif-bp-note {
	margin: 0;
}
.traveldoc-btn-container a {
	width: 100%;
	text-align: center;
}

/* Ocean ready styles for pax checkin page */
#checkin-ocean-ready {
	padding: 15px;
}
.ocean-bg {
	background: var(--neutral-cool-light);
}
.ocean-content-container {
	padding: 20px 15px;
}
.ocean-logo {
	max-width: 12%;
}
.ocean-content ol > li {
	font-size: 1.285714285714286em;
}
.ocean-content ol > li + li {
	margin-top: 20px;
}
.ocean-content ol > li  h3{
	font-size: 1em;
}
.ocean-content ol > li p {
	font-size: 0.7777777777777778em;
}
.ocean-btn-container {
	padding: 15px 0px;
	text-align: center;
}
.ocean-playstore-icons {
	text-align: center;
	padding-top: 20px;
}
.ocean-playstore-icons > img {
	height: 40px;
}
@media screen and (min-width: 40em) {
	.ocean-content-dining {
		max-width: 88%;
	}
}
@media (min-width: 768px) {
	.pif-complete .ocean-content {
		/*margin-right: 170px;
		padding-right: 15px;*/
		margin-top: 10px;
	}
	.pif-complete .ocean-btn-container {
		position: absolute;
		top: 0px;
		right: 5px;
	}
	.ocean-btn-container {
		padding: 15px;
		max-width: 180px;
	}
	.ocean-playstore-icons {
		text-align: right;
		padding: 0 0 0 15px;
	}
	.ocean-playstore-icons > img {
		width: 120px;
	}
}
.no-lspacing {
	letter-spacing: 0;
}
/*
 * GDPR form styles
 */
#gdpr-loyalty-pgm, #gdpr-news-pcl {
	border-bottom: solid 1px var(--neutral-medium);
	margin-bottom: 1.5em;
	padding-bottom: 0.5em;
}
#gdpr-news-pcl fieldset, #gdpr-news-others fieldset {
	margin: 0px;
}
.gdpr-mainfield-wrapper .form-field.radio {
	padding-left: 2.25em;
}
.gdpr-mainfield-wrapper .form-field.checkbox {
	padding-left: 1.5em;
}
.gnp-option > fieldset, .gno-option > fieldset {
	margin: 0px;
}
.gnp-option > fieldset > legend, .gno-option > fieldset > legend, .gnp-option > fieldset > legend + div, .gno-option > fieldset > legend + div {
	display: inline;
	float: left;
}
.gno-comm-options .checkbox {
	padding: 0px;
}
.gno-comm-options .checkbox > input[type="checkbox"] {
	margin-left: 10px;
    float: none;
    vertical-align: middle;
}
.gnp-notes, .gno-notes {
	float: left;
}
.mfp-content .loyalty-tpl {
    border-bottom: 5px solid var(--auxiliary-red);
    margin: 0 auto;
    overflow: inherit;
    position: relative;
    max-width: 600px;
    background-color: var(--neutral-white);
}
.gno-main-qn > input[type="checkbox"] {
	margin-left: -1.2em;
}

/**
 * Payment form credit card type styles
 */
.ccType .svg-icon {
	width: 35px;
	height: 35px;
}
.ccType .ccType-logos {
	margin-left: 0.5em;
}

/**
 * Cruise Packages entry point banner
 */
#cruise-package-entry {
	border-radius: 10px;
	margin: 25px 0 0;
	overflow: hidden;
	height: auto;
  }
  .onboardres-pg #cruise-package-entry {
	margin: 15px 15px 25px;
  }
  @media screen and (min-width: 64em) {
	#cruise-package-entry {
		margin: 25px 15px 0;
	}
  }
  @media screen and (min-width: 48em) {
	.onboardres-pg #cruise-package-entry {
		margin: 15px 0px 25px;
	}
  }
  .cruise-package-wrapper {
	display: flex;
  }
  .cp-princess-plus {
	background: var(--primary-blue-dark);
  }
  .cp-princess-premier {
	background: var(--auxiliary-blue-dark);
  }
  .cp-princess-plus,
  .cp-princess-premier {
	display: flex;
	align-items: center;
	padding: 10px 15px 15px 15px;
	flex-direction: column;
  }
  @media screen and (min-width: 40em) {
	.cruise-package-wrapper {
	  display: block;
	}
	.cp-princess-plus,
	.cp-princess-premier {
	  flex-direction: row;
	  padding: 0 20px;
	  height: 75px;
	}
  }
  .cp-learn-more {
	background: var(--neutral-white);
	border: solid 1px var(--primary-blue-dark);
	border-radius: 0 0 10px 10px;
	flex: 1;
	color: var(--primary-blue-dark);
	padding: 20px 95px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.214285714285714em;
  }
  .cp-learn-more p {
	color: var(--primary-blue-dark);
  }
  .cp-learn-more > a.button {
	border-radius: 0.5em;
	padding: 0.45em 1.25em;
	}
  #cruise-package-entry .pcl-logo-seawitch {
	display: block;
	width: 75px;
	height: 45px;
	margin: 0 auto;
  }
  .cp-logo {
	width: 135px;
  }
  .cp-logo-text {
	text-align: center;
	color: var(--neutral-white);
  }
  .cp-text {
	color: var(--neutral-white);
	margin-top: 15px;
	text-align: left;
  }
  @media screen and (min-width: 40em) {
	.cp-text {
	  margin-top: 0px;
	}
	.cp-learn-more {
	  border-radius: 0 10px 10px 0;
	  padding: 0 25px;
	  font-size: 1em;
	}
	.cp-learn-more p {
	  margin: 0 0 1em 0;
	}
  }
  @media screen and (min-width: 768px) {
	.cp-learn-more {
	  font-size: 1.071428571428571em;
	}
  }
  